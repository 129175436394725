/*
 *  @TODO
 *    - Work out why the Textarea component is not validating
 */

import React from 'react';

import { getBEMClassName, useReactHookFormValidationHelpers } from '@netfront/common-library';
import { FORM_ELEMENT_CSS_IDENTIFIERS, FORM_FIELDS } from '@netfront/gelada-identity-library';
import { Button, Input, Textarea } from '@netfront/ui-library';
import { Controller, useForm } from 'react-hook-form';

import { CONTACT_FORM_BLOCK_CSS_IDENTIFIERS, CONTACT_FORM_INITIAL_VALUES } from './ContactForm.constants';
import { IContactForm, IContactFormFields } from './ContactForm.interfaces';

const ContactForm = ({ buttonText = 'Send', isSubmitting, onSend }: IContactForm) => {
  const { form: formElementCssId } = FORM_ELEMENT_CSS_IDENTIFIERS;

  const {
    email: { id: emailId, label: emailLabel, placeholder: emailPlaceholder },
    firstName: { id: firstNameId, label: firstNameLabel, placeholder: firstNamePlaceholder },
    lastName: { id: lastNameId, label: lastNameLabel, placeholder: lastNamePlaceholder },
    message: { id: messageId, label: messageLabel },
    organisation: { id: organisationId, label: organisationLabel },
    phoneNumber: { id: phoneNumberId, label: phoneNumberLabel },
  } = FORM_FIELDS;

  const { form: formBlockCssId } = CONTACT_FORM_BLOCK_CSS_IDENTIFIERS;

  const { control, handleSubmit } = useForm<IContactFormFields>({
    defaultValues: CONTACT_FORM_INITIAL_VALUES,
    mode: 'onBlur',
    reValidateMode: 'onChange',
  });

  const { getFormFieldErrorMessage } = useReactHookFormValidationHelpers();

  return (
    <>
      <form
        className={getBEMClassName(formBlockCssId, formElementCssId)}
        onSubmit={(event) => {
          event.preventDefault();
          void handleSubmit(onSend)();
        }}
      >
        <div className="c-form__header" />
        <div className="c-form__body">
          <div className="flex -mx-2">
            <div className="c-form__field w-1/2 px-2">
              <Controller
                control={control}
                name="firstName"
                render={({ field, fieldState }) => (
                  <Input
                    additionalClassNames={getBEMClassName(formBlockCssId, firstNameId)}
                    errorMessage={getFormFieldErrorMessage(fieldState)}
                    id={`${firstNameId}-contact`}
                    isDisabled={isSubmitting}
                    labelText={firstNameLabel}
                    placeholder={firstNamePlaceholder}
                    type="text"
                    isRequired
                    {...field}
                  />
                )}
              />
            </div>
            <div className="c-form__field w-1/2 px-2">
              <Controller
                control={control}
                name="lastName"
                render={({ field, fieldState }) => (
                  <Input
                    additionalClassNames={getBEMClassName(formBlockCssId, lastNameId)}
                    errorMessage={getFormFieldErrorMessage(fieldState)}
                    id={`${lastNameId}-contact`}
                    isDisabled={isSubmitting}
                    labelText={lastNameLabel}
                    placeholder={lastNamePlaceholder}
                    type="text"
                    isRequired
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className="flex -mx-2">
            <div className="c-form__field w-1/2 px-2">
              <Controller
                control={control}
                name="email"
                render={({ field, fieldState }) => (
                  <Input
                    additionalClassNames={getBEMClassName(formBlockCssId, emailId)}
                    errorMessage={getFormFieldErrorMessage(fieldState)}
                    id={`${emailId}-contact`}
                    isDisabled={isSubmitting}
                    labelText={emailLabel}
                    placeholder={emailPlaceholder}
                    type="email"
                    isRequired
                    {...field}
                  />
                )}
              />
            </div>
            <div className="c-form__field w-1/2 px-2">
              <Controller
                control={control}
                name="phoneNumber"
                render={({ field, fieldState }) => (
                  <Input
                    additionalClassNames={getBEMClassName(formBlockCssId, phoneNumberId)}
                    errorMessage={getFormFieldErrorMessage(fieldState)}
                    id={`${phoneNumberId}-contact`}
                    isDisabled={isSubmitting}
                    labelText={phoneNumberLabel}
                    type="text"
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className="c-form__field">
            <Controller
              control={control}
              name="organisationName"
              render={({ field, fieldState }) => (
                <Input
                  additionalClassNames={getBEMClassName(formBlockCssId, organisationId)}
                  errorMessage={getFormFieldErrorMessage(fieldState)}
                  id={`${organisationId}-contact`}
                  isDisabled={isSubmitting}
                  labelText={organisationLabel}
                  type="text"
                  isRequired
                  {...field}
                />
              )}
            />
          </div>
          <div className="c-form__field">
            <Controller
              control={control}
              name="message"
              render={({ field, fieldState }) => (
                <Textarea
                  additionalClassNames={getBEMClassName(formBlockCssId, messageId)}
                  errorMessage={getFormFieldErrorMessage(fieldState)}
                  id={`${messageId}-contact`}
                  isDisabled={isSubmitting}
                  labelText={messageLabel}
                  isRequired
                  {...field}
                />
              )}
            />
          </div>
        </div>
        <div className="c-form__footer">
          <Button isDisabled={isSubmitting} text={buttonText} type="submit" />
        </div>
      </form>
    </>
  );
};

export { ContactForm };
