/* eslint-disable @typescript-eslint/indent */
import { useHowlerMutation } from '@netfront/gelada-identity-library';

import { SEND_HOWLER_CONTACT_FORM_MUTATION } from './useSendHowlerContactForm.graphql';
import {
  IHandleSendHowlerContactFormParams,
  ISendHowlerContactFormMutationGraphQLResponse,
  ISendHowlerContactFormMutationVariables,
  IUseSendHowlerContactFormOptions,
  IUseSendHowlerContactForm,
} from './useSendHowlerContactForm.interfaces';

const useSendHowlerContactForm = (options?: IUseSendHowlerContactFormOptions): IUseSendHowlerContactForm => {
  const { mutation, onCompleted, onError, token } = options ?? ({} as IUseSendHowlerContactFormOptions);

  const [executeSendHowlerContactForm, { loading: isLoading }] = useHowlerMutation<
    ISendHowlerContactFormMutationGraphQLResponse,
    ISendHowlerContactFormMutationVariables
  >({
    mutation: mutation ?? SEND_HOWLER_CONTACT_FORM_MUTATION,
    options: {
      onCompleted: (data: ISendHowlerContactFormMutationGraphQLResponse) => {
        if (!onCompleted) {
          return;
        }

        const {
          notification: { sendContactForm: isCompleted },
        } = data;

        onCompleted({
          isCompleted,
        });
      },
      onError,
    },
    token,
  });

  const handleSendHowlerContactForm = async ({
    email,
    firstName,
    lastName,
    message,
    phoneNumber,
    placeholders,
    projectId,
    templateId,
  }: IHandleSendHowlerContactFormParams) => {
    await executeSendHowlerContactForm({
      variables: {
        email,
        firstName,
        lastName,
        message,
        phoneNumber,
        placeholders,
        projectId,
        templateId,
      },
    });
  };

  return {
    handleSendHowlerContactForm,
    isLoading,
  };
};

export { useSendHowlerContactForm };
